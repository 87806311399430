<template>
    <div>
        <v-container fluid class="pt-0 mt-5">
            <v-row>
                <v-col cols="12">
                    <!-- 框 -->
                    <v-alert :dismissible="false" class="grey white--text" dark border="left" icon="fas fa-book-reader">
                        <span>Visits</span>
                    </v-alert>
                </v-col>
                <v-col cols="12" class="px-5 pt-0">
                    <v-row dense v-if="loading"> 
                        <v-col cols="12">
                            <v-skeleton-loader dense type="table-row-divider@20" class="mx-auto"></v-skeleton-loader>
                        </v-col>
                    </v-row>
                    <!-- 表头 -->
                    <v-data-table dense :headers="visitHeaders" :items="visitList" hide-default-footer v-if="!loading">
                        <!-- 表头上侧 -->
                        <!-- <template v-slot:top>
                            <v-toolbar flat color="white">
                                <v-btn outlined color='blue' >ALL</v-btn>&nbsp;&nbsp;&nbsp;
                                <v-col cols="12" sm="6" md="4" lg="2" class="mt-5">
                                <v-menu
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    full-width
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                    <v-text-field
                                        v-model="date"
                                        label="Date"
                                        prepend-icon="event"
                                        readonly
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date" @input="menu = false"></v-date-picker>
                                </v-menu>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <v-divider />
                        </template> -->
                        <!-- 表数据 -->
                        <template v-slot:body="{ visit }">
                            <tbody>
                                <tr v-for="(visit,index) in visitList" :key="visit.visitId">
                                    <td>{{(indexNo-1)* pageSize +(index+1)}}</td>
                                    <!-- <td>{{ visit.visitId }}</td> -->
                                    <td>{{ common.dateFormat(visit.date) }}</td>
                                    <td>{{ visit.volume }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                    <v-divider v-if="!loading"></v-divider>
                    <!-- 底部分页 -->
                    <template>
                        <div class="text-xs-center mt-5" v-if="totalElements>0">
                            <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" color="grey" circle v-model="pageNo" :length="totalPage" @input="getVisitList" :total-visible="20">
                            </v-pagination>
                        </div>
                    </template>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Vue from 'vue';
    import config from '@/config';
    import { mapGetters } from "vuex";
    export default {
        data() {
            return {
                visitHeaders: [{
                        text: 'No',
                        value: 'no',
                        sortable: false,
                    },
                    // {
                    //     text: 'Visit Id',
                    //     value: 'visitId',
                    //     sortable: false,
                    // },
                    {
                        text: 'Date',
                        value: 'date',
                        sortable: false,
                    },
                    {
                        text: 'Volume',
                        value: 'voleme',
                        sortable: false,
                    },
                ],
                visitList: [],
                totalElements: 0,
                totalPage: 0,
                pageNo: 1,
                pageSize: 20,
                menu:false,
                date:null,
                indexNo:0,
                loading:false
            }
        },
        computed: {
            ...mapGetters(['user']),
        },
        watch: {

        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({
                    name: 'login'
                })
            };
            // if(Number(localStorage.getItem("iobots-admin-expires"))-((new Date()).getTime()) > 0){
            //     localStorage.setItem("iobots-admin-expires", (new Date()).getTime() + 1000 * 60 * 60 * 2)
            // };
            this.getVisitList(1);
        },
        created() {

        },
        methods: {
            // 获取访问数据
            getVisitList(pageNo) {
                this.loading = true;
                let api = config.baseUrl + '/visit/private/getVisitList/'+(pageNo - 1)+'/'+this.pageSize
                this.$http.get(api).then(
                    response => {
                        if (response.data.code == 200) {
                            this.loading = false;
                            this.visitList = response.data.result.visitList;
                            this.totalElements = response.data.result.totalElements;
                            this.totalPage = Math.ceil(this.totalElements / this.pageSize);
                            this.indexNo = pageNo;
                        }
                    },
                )
            },
        }
    }
</script>